<template>
  <div class="zy-gant-plus-head">
    <div
      class="zy-gant-plus-head-month"
      v-for="(month, month_index) in plus.headList"
      :key="`${month.title}_${month_index}`"
    >
      <div class="zy-gant-plus-head-month-title">{{ month.title }}</div>
      <div class="zy-gant-plus-head-month-days">
        <div
          :class="{ gantSelect: (day.des[0] <= curDate && curDate <= day.des[day.des.length-1])}"
          :style="{ width: `${day.des.length * plus.config.dayWidth}px` }"
          v-for="(day, day_index) in month.days"
          :key="`day_${day_index}`"
        >
          {{ day.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GantPlus from "./gantPlus"
import moment from 'moment'
export default {
  props: {
    plus: GantPlus,
  },
  computed:{
    curDate() {
      return new Date(moment(new Date()).format('YYYY-MM-DD')+ ' 00:00:00').getTime()
    }
  },
}
</script>

<style lang="less" scoped>
.zy-gant-plus-head {
  display: flex;
  flex-direction: row;
  &-month {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    line-height: 14px;
    height: 60px;
    text-align: center;
    & + & {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }
    &-title {
      width: 100%;
      height: 30px;
      padding: 8px 0;
    }
    &-days {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      flex-shrink: 0;
      height: 30px;

      & > div {
        padding: 8px 0;
      }
      & > div:nth-child(2) {
        border-left: 1px solid rgba(255, 255, 255, 0.1);
      }
      .gantSelect{
        background: #66BBF9;
      }
    }
    &-title + &-days {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
</style>
