<template>
  <div class="zy-gant-plus">
    <el-table
      ref="table"
      class="gant-table"
      :data="plus.list"
      height="100%"
      row-key="id"
      lazy
      :load="load"
      :header-cell-style="headerStyle"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      @expand-change="handleSelectionChange"
    >
      <slot></slot>
      <el-table-column
        class-name="gant-right"
        prop="de"
        label="甘特图"
        :width="plus.width"
      >
        <template slot="header">
          <gant-head :plus="plus"></gant-head>
        </template>
        <template slot-scope="{ row }">
          <gant-row
            :plus="plus"
            :row="row"
            :config="config"
            :key="index"
            v-for="(config, index) in plus.config.list"
          ></gant-row>
          <div class="gant-line" :style="{left: `${lineLeft * plus.config.dayWidth}px`}"></div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import GantPlus from "./gantPlus"
import GantHead from "./head.vue"
import GantRow from "./row.vue"
export default {
  components: { GantHead, GantRow },
  data() {
    return {
      plus: new GantPlus(this.config),
      left:0
    }
  },
  created() {
    this.load()  
  },
  props: {
    //列表数据请求接口
    request: Function,
    // 甘特图配置
    config: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed:{
    lineLeft() {
      //获取计划开始的时间戳
      const start = new Date().getTime()
      let length = 0 // 记录开始距离左边的天数
      this.plus.headList?.forEach((month) => {
        month.days.forEach((dayRand) => {
          dayRand.des.forEach((dayTime) => {
            //对比两个时间戳的大小，如果小如开始时间，则当天计划还没开始。
            if (dayTime < start) {
              length++
            }
          })
        })
      })
      this.$nextTick(() => {
        setTimeout(() => { // 延迟执行，等待dom渲染完成，否则offsetLeft取值不准确
        // 滚动条滚动到显示当前日期的位置
          let arr = Array.from(document.querySelectorAll('.zy-gant-plus'))
          // 区分页面内和弹窗的横向滚动
          arr.map((p,i) => {
            let gantSelect = document.querySelectorAll('.gantSelect')[i]
            let left = document.querySelectorAll('.el-table__header-wrapper .gant-right')[i]?.offsetLeft
            let width = arr[i]?.clientWidth
            let space = width - left - gantSelect.clientWidth
            let dom = document.querySelectorAll('.gant-table .el-table__body-wrapper')[i]
            if(gantSelect.offsetLeft > space) dom.scrollLeft = gantSelect.offsetLeft - space
          })
        },100)
      })
      return length
    },
  },
  methods: {
    load(tree, treeNode, resolve) {
      if (!this.request) return
      this.request(tree, treeNode)
        .then((res) => {
          if (resolve) resolve(res.data)
          this.plus.setData(res.data, tree)
        })
        .catch((err) => {})
    },
    headerStyle() {
      return {
        background: "#1E5087",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#FFFFFF",
        lineHeight: "21px",
        borderBottom: "unset",
        height: "60px",
        padding: "unset",
      }
    },
    handleSelectionChange(row, expand) {},
  },
}
</script>

<style lang="less" scoped>
.zy-gant-plus {
  position: relative;
  width: 100%;
  height: 100%;
}
.zy-gant {
  &-table {
    overflow: hidden;
    flex-shrink: 0;
    font-size: 0;
  }
}
.gant-right{
  /deep/ .cell{
    padding: 0;
  }
}
.gant-line{
  position: absolute;
  height: 40px;
  top: 0px;
  bottom: 0;
  width: 1px;
  border-left: 1px dashed #FC4A66;
}
/deep/ .el-table__body .el-table__row.hover-row td {
	background-color: transparent !important;
}
</style>
<style>
.gant-table tr {
  background: linear-gradient(to left,#17345c,#102145 );
}
/* row底部分割线划分 */
.gant-table td.el-table__cell {
  border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
  line-height: 21px;
  height: 40px;
  box-sizing: border-box;
}
.gant-table td.el-table__cell {
  padding: 9px 0;
}
.gant-table td.el-table__cell > div {
  color: red;
  background: transparent;
  font-size: 14px;
  color: #ffffff;
  line-height: 20px;
  height: 20px;
}
.gant-table::before,
.gant-table,
.gant-table .el-table__expanded-cell {
  background: transparent;
}
.gant-table .el-table__expand-icon {
  background: #1e5087;
  color: #ffffff;
  margin-right: 12px !important;
}
.gant-table .el-table__body-wrapper{
  min-height: 400px !important;
}
.gant-table .el-table__body-wrapper::-webkit-scrollbar{
  width: 0px;
  height: 17px;
  background-color: #aaa;
  border-radius: 9px;
}
.gant-table .el-table__body-wrapper::-webkit-scrollbar-thumb{
  background-color: #fff;
  border-radius: 9px;
}
.gant-table .el-table__fixed::before{
  width: 0;
}
</style>
